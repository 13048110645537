import React from "react"

import { Title } from "../components/common"
import { Layout, SEO } from "../components/Layout"
import { Image } from "../components/helpers"
import { ContactInfo, Education, Biography, Awards } from "../components/About"

const About = () => (
  <Layout>
    <SEO title="About" />

    <Title
      name="Kevin Rudin"
      subTitle="Managing Attorney"
      img="miha-rekar-j91lQR9UGZA.jpg"
    />

    <div className="bg-gray-50">
      <div className="flex flex-col items-center max-w-screen-xl px-4 py-8 mx-auto space-x-0 space-y-8 sm:space-y-0 sm:px-6 lg:px-8 sm:flex-row sm:items-start sm:space-x-16">
        <Image
          className="object-cover w-64 w-full mb-12 sm:mb-0"
          src="rudin-hero.png"
          alt="Kevin Rudin"
        />
        <div className="flex flex-col w-full space-y-8">
          <ContactInfo />
          {/* <AreasOfPractice /> */}
          <Education />
        </div>
        <div className="w-full mt-8 sm:mt-0">
          <Awards />
        </div>
      </div>
    </div>

    <Biography />
  </Layout>
)

export default About
