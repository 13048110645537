import React from "react"

export const Biography = () => {
  return (
    <article className="max-w-screen-xl px-6 m-6 mx-auto mt-4 space-y-8 text-xl leading-7 text-gray-500 sm:my-12">
      <h2 className="text-3xl font-extrabold leading-9 tracking-tight text-gray-900 header-underline sm:text-4xl sm:leading-10">
        More about Kevin
      </h2>
      <p>
        Kevin Rudin is a civil trial attorney dedicated to representing Florida
        insureds who have disputes with their insurance companies. After
        obtaining his undergraduate degree from Indiana University, Kevin
        returned to his home state of Florida and graduated from Stetson
        University College of Law. He gained experience representing property
        owners while spending the better part of a decade employed by one of the
        state’s leading sinkhole litigation firms. Kevin then founded Rudin
        Legal, P.A. in order to best serve his statewide clients who have been
        denied or underpaid on a wide variety of residential and commercial
        property insurance claims.
      </p>

      <p>
        Kevin has been admitted to the Florida Bar since 2009, and the United
        States District Court Middle District of Florida since 2013. For four
        consecutive years beginning in 2016, Kevin was selected to Super Lawyers
        Rising Stars, an honor bestowed upon less than 2.5 percent of attorneys
        in Florida.
      </p>

      <p>
        A native of Miami, Kevin has called the Tampa Bay area home since 2006.
        He currently lives in Tampa with his wife and two children.
      </p>
    </article>
  )
}
