import React from "react"
import { Helmet } from "react-helmet"

import { SuperLawyers } from "../helpers"

export const Awards = () => {
  return (
    <div>
      <Helmet>
        {/* <script
          async
          defer
          type="text/javascript"
          src="https://www.superlawyers.com/static/sl-badge/v1/load.min.js"
        /> */}
      </Helmet>
      <SuperLawyers />
      <h3 className="text-base font-semibold leading-6 tracking-wide uppercase text-secondary-600">
        Awards
      </h3>
      <div className="mt-2 text-sm text-gray-600">
        <div
          data-slbadge="v1-rsbadge-red"
          style={{
            width: "180px",
            height: "150px",
            borderRadius: "12px",
            fontRamily: "arial, sans-serif",
            color: "gray",
            textAlign: "center",
          }}
        >
          <a
            className="slbadge_profileurl"
            title="View the profile of Florida Civil Litigation Attorney Kevin Rudin"
            href="https://profiles.superlawyers.com/florida/tampa/lawyer/kevin-rudin/1ace475a-d11a-473c-a5d3-b03b58533fb5.html?utm_source=1ace475a-d11a-473c-a5d3-b03b58533fb5&utm_campaign=v1-rsbadge-red&utm_content=profile"
          >
            Kevin Rudin
          </a>
          <div style={{ marginTop: "6px" }}>Rated by Super Lawyers</div>
        </div>
      </div>
    </div>
  )
}
