import React from "react"

import { Image } from "../helpers"

export const Title = props => {
  const { name, subTitle, img } = props
  const height = subTitle ? "10rem" : "8rem"

  return (
    <div className="relative mt-6 overflow-hidden">
      {img && (
        <Image
          className="absolute inset-0 object-cover w-full h-full opacity-25"
          style={{ filter: "grayscale(100%)", maxHeight: height }}
          src={img}
        />
      )}
      <div className="absolute inset-0 max-w-screen-xl px-4 pt-8 pb-4 mx-auto mb-6 text-center sm:px-6 lg:px-8">
        <h2 className="text-4xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          {name}
        </h2>
        {subTitle && (
          <p className="mt-2 text-xl leading-7 text-gray-800">{subTitle}</p>
        )}
      </div>
    </div>
  )
}
