import React from "react"

export const Education = () => {
  return (
    <div className="">
      <h3 className="text-base font-semibold leading-6 tracking-wide uppercase text-secondary-600">
        Education
      </h3>
      <div className="mt-2 text-base leading-6 text-gray-500">
        <ul>
          <li>
            <div>
              <b>Stetson University College of Law</b>
            </div>
            <div>Gulfport, Florida</div>
            <div>Juris Doctor - 2009</div>
          </li>
          <li className="mt-2">
            <div>
              <b>Indiana University</b>
            </div>
            <div>Bloomington, Indiana</div>
            <div>Bachelor of Science - 2006</div>
          </li>
        </ul>
      </div>
    </div>
  )
}
