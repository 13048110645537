import React from "react"

export const ContactInfo = () => {
  return (
    <div className="">
      <h3 className="text-base font-semibold leading-6 tracking-wide uppercase text-secondary-600">
        Contact
      </h3>
      <div className="mt-2 text-base leading-6 text-gray-500">
        <div>
          <dt className="sr-only">Phone number</dt>
          <dd className="flex">
            <span>Tel:</span>
            <span className="ml-3">
              <a href="tel:+18134287535">+1 (813) 428-7535</a>
            </span>
          </dd>
        </div>
        <div>
          <dt className="sr-only">Email</dt>
          <dd className="flex">
            <span>Email:</span>
            <span className="ml-3">
              <a
                href="mailto:kevin@rudinlegal.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                kevin@rudinlegal.com
              </a>
            </span>
          </dd>
        </div>
        {/* <div>LinkedIn, Facebook, etc</div> */}
        <div>
          <a
            className="inline-block font-medium transition duration-150 ease-in-out text-secondary-500 hover:text-secondary-900 focus:outline-none focus:text-gray-900"
            href="https://goo.gl/maps/c2XR6k9g9Ds2se8k9"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get Directions
          </a>
        </div>
      </div>
    </div>
  )
}
